<template>
  <div
    ref="layout"
    class="layout"
    :class="{'layout-mobile': isMobile}"
  >
    <layout-m-header
      v-if="isMobile"
      :scroll-top="scrollTop"
    />
    <layout-header v-else />
    <section>
      <transition
        name="fade-transform"
        mode="out-in"
      >
        <keep-alive>
          <router-view
            v-if="isRouterAlive"
            :key="key"
          />
        </keep-alive>
      </transition>
    </section>
    <layout-m-footer v-if="isMobile" />
    <layout-footer v-else />
    <float-menu
      v-if="showMenu && !isMobile"
      :scroll-top="scrollTop"
    />
  </div>
</template>
<script>

import LayoutFooter from './modules/Footer'
import LayoutHeader from './modules/Header'
import FloatMenu from './modules/FloatMenu'
import isMobile from 'ismobilejs'
import LayoutMHeader from '@/components/Layout/modules/Mobile/MHeader.vue'
import LayoutMFooter from '@/components/Layout/modules/Mobile/MFooter.vue'

export default {
  name: 'LayoutView',
  components: { LayoutMFooter, LayoutMHeader, FloatMenu, LayoutHeader, LayoutFooter },
  props: {
    showMenu: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      isRouterAlive: true,
      isMobile: isMobile().phone || isMobile().tablet,
      scrollTop: 0
    }
  },
  computed: {
    key() {
      return this.$route.path
    }
  },
  mounted() {
    const path = this.$route.path
    if (this.isMobile) {
      if (path.indexOf('/m/') === -1) {
        this.$router.push('/m' + path)
      }
    } else {
      if (path.indexOf('/m/') !== -1) {
        this.$router.push(path.replace('/m', ''))
      }
    }
    this.listenScroll()
  },
  methods: {
    listenScroll() {
      window.addEventListener('scroll', event => {
        this.scrollTop = (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .layout{
    >header + section{
      padding-top: 146px;
    }
    >section{
      background: #F4F5FA;
      min-height: calc(100vh - 256px);
    }
    &-mobile{
      >header + section{
        padding-top: 6.5rem;
      }
      >section{
        background: #F4F5FA;
        min-height: auto;
      }
    }
  }
</style>
