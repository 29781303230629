<template>
  <div
    v-if="Object.keys(order).length"
    class="container order-view"
  >

    <div
      class="success"
      flex="main:center cross:center"
    >
      <van-icon
        name="passed"
        color="#71B247"
      />
      下单成功，请保存好订单号！
    </div>

    <div
      class="order-info"
      flex="main:left"
    >
      <div class="order-info__tips">
        <div class="item">订单号:{{ order.id }} <span @click="handleCopy(order.id)">[复制]</span></div>
        <div class="item item__success">购买成功</div>
      </div>
      <div class="order-info__step">
        <div class="tips">订单已经完成，感谢您使用！</div>
        <div class="step">
          <van-steps :active="3">
            <van-step>
              <h3>提交订单</h3>
              <p>{{ order.create_time }}</p>
            </van-step>
            <van-step>
              <h3>付款成功</h3>
              <p>{{ order.pay.pay_dt }}</p>
            </van-step>
            <van-step>
              <h3>购买成功</h3>
              <p>{{ order.pay.pay_dt }}</p>
            </van-step>
          </van-steps>
        </div>
      </div>
    </div>

    <div class="address-info">
      <div class="title">收货人信息</div>
      <div class="item">收货人姓名：{{ order.address.name }}</div>
      <div class="item">收货人姓名：{{ order.address.tel }}</div>
      <div class="item">收货人地址：{{ order.address.address_region }}{{ order.address.address_detail }}</div>
    </div>

    <div class="order-detail">
      <table class="table">
        <thead>
          <tr>
            <th>商品</th>
            <th width="160px">单价（元）</th>
            <th width="160px">数量</th>
            <th width="160px">小计（元）</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, key) in order.details"
            :key="key"
          >
            <td>
              <div
                class="goods-info"
                flex="main:left cross:center"
              >
                <van-image
                  height="80px"
                  width="80px"
                  :src="item.cover_img+'?x-oss-process=image/resize,w_500,limit_0'"
                />
                <span> {{ item.goods_name }}</span>
              </div>
            </td>
            <td>&yen;{{ item.sale_price }}</td>
            <td>{{ item.goods_num }}</td>
            <td>&yen;{{ (item.goods_num * item.sale_price).toFixed(2) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OrderView',
  data() {
    return {
      id: '',
      order: {}
    }
  },
  created() {
    this.id = this.$route.params.id
    this.getInfo()
  },
  methods: {
    handleCopy(id) {
      navigator.clipboard.writeText(`您的订单号为：${id}, \n订单地址为：http://mall.duoduosodt.cn/order/${id}/success`)
      this.$toast.success('订单号已复制')
    },
    getInfo() {
      this.$api.order.detail(this.id).then(res => {
        this.order = res.data
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/style/variable.scss";
$success: #71B247;
.order-view {
  padding: 36px 0 72px;

  .success{
    font-size: 24px;
    color: $success;
    margin-bottom: 32px;
  }

  .order-info{
    background: #ffffff;
    padding: 16px 32px;
    border-top: 4px solid $success;
    position: relative;
    &:after{
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 4px;
      background-position: 0 -117px;
      background-repeat: repeat-x;
      background-image: url("@/assets/order-icons.png");
    }
    &__tips{
      border-right: 1px solid #eee;
      width: 320px;
      padding-bottom: 72px;

      .item{
        color: #999;
        &__success{
          margin-top: 48px;
          text-align: center;
          color: $success;
          font-size: 36px;
          font-weight: 600;
        }
        span{
          cursor: pointer;
          &:hover{
            color: $main-color;
          }
        }
      }
    }
    &__step{
      padding-left: 42px;
      flex: 1;

      .tips{
        color: #999;
      }

      .step::v-deep{
        margin-top: 36px;
        .van-step{
          height: 108px;

          h3{
            color: $success;
            font-size: 18px;
          }
          p{
            color: #999;
          }
          &__circle-container,
          &__line{
            top: 52px;
          }

        }
      }
    }
  }

  .address-info{
    background: #fff;
    padding: 16px 32px 48px;
    margin-top: 42px;

    .title{
      font-size: 18px;
      color: #999;
      margin-bottom: 32px;
    }
    .item{
      font-size: 16px;
      color: #333;
      padding: 2px 0;
    }
  }

  .order-detail{
    margin-top: 42px;
    .table{
      width: 100%;
      border: 1px solid #ebebeb;
      border-spacing: 0;
      margin-bottom: 42px;
      thead{
        background: #EBEBEB;
        color: #666;
        height: 42px;
      }

      tbody{
        background: #fff;
      }

      tr>th,
      tr>td{
        padding: 12px 0;
        font-size: 16px;
        text-align: center;
        border-top: 1px solid #eee;
      }

      .goods-info{
        width: 310px;
        padding: 0 16px;

        span{
          text-align: left;
          padding-left: 12px;
        }
      }
    }
  }
}
</style>
