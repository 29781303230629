// import Cache from '@/utils/cache'
import Api from '@/api'
// const CACHE_KEY = 'cart_key'
const getDefaultState = () => {
  return {
    data: {}
  }
}

const state = getDefaultState()

const mutations = {
  INIT: (state) => {
    state.data = {}
    Api.order.cartData().then(res => {
      state.data = JSON.parse(res.data)
    })
  },
  SET_DATA: (state, data) => {
    state.data = {}
    state.data = data
    Api.order.cartStore({ data: JSON.stringify(data) }).then(res => {
    })
  }
}

const actions = {
  init({ commit }) {
    commit('INIT')
  },
  store({ commit }, data) {
    commit('SET_DATA', data)
  },
  clear({ commit }, data) {
    commit('SET_DATA', {})
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

