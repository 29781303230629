/**
 * Created by JianJia.Zhou<jianjia.zhou> on 2023/11/22.
 */
import request from '@/utils/request'

export function setting() {
  return request({
    url: `/mch/market/setting`,
    method: 'get'
  })
}

export default {
  setting
}
