import Vue from 'vue'
import Vant from 'vant'
import App from './App.vue'
import router from './router'
import store from './store'
import Api from './api'
import { DDLoading } from '@/components/DDLoading'
import 'nprogress/nprogress.css'
import 'vant/lib/index.css'
import 'flex.css'
import { setToken } from '@/utils/auth'

Vue.config.productionTip = false

if (!Api.user.isLogin()) {
  await Api.user.login().then(res => {
    setToken(res.data.token)
  })
} else {
  await store.dispatch('cart/init')
}

Vue.use(Vant)
Vue.use(DDLoading)
Vue.use({
  install(Vue, options) {
    Vue.prototype.$api = Api
  }
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
