<template>
  <div class="wrapper">
    <!--  banner  -->
    <van-swipe
      class="dd-swipe"
      :autoplay="5000"
      indicator-color="white"
    >
      <van-swipe-item
        v-for="(item, key) in banner"
        :key="key"
      >
        <img
          :src="item.img"
        >
      </van-swipe-item>
    </van-swipe>
    <!-- 热销   -->
    <div
      v-if="hots.length"
      class="container goods-box"
    >
      <div class="title">热销商品</div>
      <div
        class="goods-warp"
        flex="main:left"
      >
        <div
          v-for="(arr, index) in hots"
          :key="`hot_index`+index"
          :class="{
            'goods-warp__special': index === 0,
            'goods-warp__normal': index > 0,
          }"
        >
          <router-link
            v-for="(item) in arr"
            :key="`hot_goods`+item.id"
            class="goods-item"
            flex="dir:top cross:center"
            :to="`/detail/${item.id}`"
          >
            <van-image
              fit="contain"
              :src="item.cover_img+'?x-oss-process=image/resize,w_500,limit_0'"
            />
            <span class="goods-item__name">{{ item.name }}</span>
            <span class="goods-item__desc">{{ item.desc }}</span>
            <span class="goods-item__price"> &yen;{{ item.sale_price }}</span>
          </router-link>
        </div>
      </div>
    </div>
    <!--  微店广告   -->
    <a
      v-if="hots.length"
      href="http://www.duoduosoft.cn/?product/29.html"
      target="_blank"
      class="container dd-trade dd-trade__weidian"
    />
    <!-- 分类   -->
    <div class="container goods-box goods-box__cate">
      <div class="title">{{ category.name }}</div>
      <div
        class="goods-warp"
        flex="main:left"
      >
        <div
          v-for="(arr, index) in goodsList"
          :key="`goods_index`+index"
          class="goods-warp__normal"
        >
          <router-link
            v-for="(item) in arr"
            :key="`goods`+item.id"
            class="goods-item"
            flex="dir:top cross:center"
            :to="`/detail/${item.id}`"
          >
            <van-image
              fit="contain"
              :src="item.cover_img+'?x-oss-process=image/resize,w_500,limit_0'"
            />
            <span class="goods-item__name">{{ item.name }}</span>
            <span class="goods-item__desc">{{ item.desc }}</span>
            <span class="goods-item__price"> &yen;{{ item.sale_price }}</span>
          </router-link>
        </div>
      </div>
    </div>
    <!--  微店广告   -->
    <a
      href="http://www.duoduosoft.cn/?product/30.html"
      target="_blank"
      class="container dd-trade dd-trade__app"
    />
  </div>
</template>

<script>

export default {
  name: 'IndexView',
  data() {
    return {
      banner: [],
      hots: [],
      goodsList: [],
      category: {}
    }
  },
  computed: {
  },
  async mounted() {
    await this.getBanner()
    await this.getGoods()
  },
  methods: {
    async getBanner() {
      await this.$api.market.setting().then(res => {
        this.banner = res.data
      })
    },
    async getGoods() {
      await this.$api.mch.goods().then(res => {
        this.hots = res.data.hots
        this.goodsList = res.data.goods_list
        this.category = res.data.category
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/style/variable.scss";
.wrapper{
  background: #f5f5f5;
  .dd-swipe .van-swipe-item {
    color: #fff;
    font-size: 20px;
    height: 480px;
    text-align: center;
    img{
      height: inherit;
      width: 100%;
    }
  }
  .container.dd-trade{
    display: block;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    height: 120px;
    margin-top: 60px;
    margin-bottom: 100px;
    &__weidian{
      background-color: #6431DA;
      background-image: url("@/assets/weidian.png");
      background-position: center -130px;
      background-size: 80% 400px;
    }
    &__app{
      background-color: #F878CD;
      background-image: url("@/assets/app.png");
      background-position: center -130px;
      background-size: 80% 400px;
      margin-bottom: 0;
    }
  }
  .container.goods-box{
    margin-top: 54px;
    &__cate{
      .goods-warp{
        .goods-item{
          height: 420px !important;
          &__price{
            position: absolute;
            bottom: 20px;
          }
        }
      }
    }
    &:last-child{
      margin-bottom: 40px;
    }
    .title{
      font-size: 24px;
      border-left: 4px solid $main-color;
      padding-left: 12px;
    }

    .goods-warp{
      margin-top: 24px;
      .goods-item{
        flex: 1;
        height: 400px;
        background: #fff;
        transition: .3s;
        display: flex;
        cursor: pointer;
        padding: 12px;
        position: relative;

        &:first-child{
          margin-bottom: 10px;
        }
        &:hover{
          box-shadow: 0 4px 12px rgba(0,0,0,.15);
          color: #333;
        }

        .van-image{
          height: 240px;
          width: 100%;
        }

        &__name{
          font-size: 20px;
          margin-top: 12px;
          padding: 0 32px;
          text-align: center;
        }
        &__desc{
          font-size: 16px;
          color: #bbb;
          margin-top: 8px;
          padding: 0 32px;
          text-align: center;
        }
        &__price{
          margin-top: 26px;
          color: $main-color;
          font-size: 20px;
        }
      }

      &__special{
        flex: 1;
        .goods-item{
          height: 100%;
          margin-bottom: 0 !important;

          .van-image{
            height: 60%;
          }

          &__name{
            margin-top: 48px;
          }
          &__price{
            color: $main-color;
            position: absolute;
            bottom: 20px;
          }
        }
      }
      &__normal{
        flex: 1;
        &:nth-child(n+2){
          margin-left: 10px;
        }
      }

    }
  }
}
</style>
