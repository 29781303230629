<template>
  <div class="container cart-view">
    <template v-if="Object.keys(cartData).length">
      <cart-goods />
      <div
        class="btns"
        flex="main:justify cross:center"
      >
        <span />
        <div>
          <router-link to="/">
            <van-button
              type="primary"
              class="sale-btn"
            >继续购物</van-button>
          </router-link>
          <router-link to="/order">
            <van-button
              type="primary"
              class="buy-btn"
            >去结算</van-button>
          </router-link>
        </div>
      </div>
    </template>
    <!--  购物车为空    -->
    <template v-else>
      <van-empty
        description="购物车暂无数据"
        flex="main:center cross:center"
      >
        <router-link to="/">
          <van-button
            round
            type="primary"
            class="empty-btn"
          >继续购物</van-button>
        </router-link>
      </van-empty>
    </template>
  </div>
</template>

<script>
import CartGoods from '@/components/CartGoods'
export default {
  name: 'CartView',
  components: { CartGoods },
  data() {
    return {
    }
  },
  computed: {
    cartData() {
      return this.$store.getters.cartData
    }
  },
  watch: {
    cartData: {
      deep: true,
      immediate: true,
      handler(val) {

      }
    }
  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
@import "@/style/variable.scss";
.cart-view {
  padding: 36px 0 72px;

  .empty-btn{
    width: 220px;
    height: 42px;
    border-radius: 50px !important;
    background: #fff;
    margin-top: 56px;
    background: linear-gradient(to right, #F18C0F ,#E8420B);
  }
  .sale-btn{
    width: 160px;
    height: 56px;
    border-radius: 0;
    background: #fff;
    color: #E8420B;
    border: 1px solid #E8420B !important;
  }
  .buy-btn{
    margin-left: 42px;
    width: 160px;
    height: 56px;
    border-radius: 0;
    background: linear-gradient(to right, #F18C0F ,#E8420B);
  }
}
</style>
