<template>
  <div class="wrapper">
    <!-- 分类   -->
    <div class="container goods-box goods-box__cate">
      <div
        class="goods-warp"
      >
        <div
          v-for="(arr, index) in goodsList"
          :key="`goods_index`+index"
          class="goods-warp__normal"
          flex="main:left"
        >
          <router-link
            v-for="(item) in arr"
            :key="`goods`+item.id"
            class="goods-item"
            flex="dir:top cross:center"
            :to="`/detail/${item.id}`"
          >
            <van-image
              fit="contain"
              :src="item.cover_img+'?x-oss-process=image/resize,w_500,limit_0'"
            />
            <span class="goods-item__name">{{ item.name }}</span>
            <span class="goods-item__desc">{{ item.desc }}</span>
            <span class="goods-item__price"> &yen;{{ item.sale_price }}</span>
          </router-link>
          <template v-if="arr.length < 4">
            <div
              v-for="num in 4 - arr.length"
              :key="`blank${num}`"
              class="goods-item"
              style="visibility: hidden;"
            />
          </template>
        </div>
      </div>
    </div>
    <!--  微店广告   -->
    <a
      href="javascript:;"
      class="container dd-trade dd-trade__app"
    />
  </div>
</template>

<script>

export default {
  name: 'CateView',
  data() {
    return {
      goodsList: [],
      cid: 0,
      loading: true
    }
  },
  computed: {

  },
  async mounted() {
    this.cid = this.$route.params.id
    await this.getGoods()
  },
  methods: {
    async getGoods() {
      this.loading = true
      await this.$api.mch.goods({ category_id: this.cid }).then(res => {
        this.loading = false
        this.hots = res.data.hots
        this.goodsList = res.data.goods_list
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/style/variable.scss";
.wrapper{
  background: #f5f5f5;
  .container.dd-trade{
    display: block;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    height: 120px;
    margin-top: 60px;
    margin-bottom: 100px;
    &__weidian{
      background-color: #6431DA;
      background-image: url("@/assets/weidian.png");
      background-position: center -130px;
      background-size: 80% 400px;
    }
    &__app{
      background-color: #F878CD;
      background-image: url("@/assets/app.png");
      background-position: center -130px;
      background-size: 80% 400px;
      margin-bottom: 0;
    }
  }
  .container.goods-box{
    &__cate{
      .goods-warp{
        .goods-item{
          &__price{
            position: absolute;
            bottom: 20px;
          }
        }
      }
    }
    &:last-child{
      margin-bottom: 40px;
    }
    .title{
      font-size: 24px;
      border-left: 4px solid $main-color;
      padding-left: 12px;
    }

    .goods-warp{
      margin-top: 24px;
      .goods-item{
        flex-basis: 25%;
        height: 400px;
        background: #fff;
        transition: .3s;
        display: flex;
        cursor: pointer;
        padding: 12px;
        position: relative;
        margin-left: 10px;

        &:first-child{
          margin-left: 0;
        }
        &:hover{
          box-shadow: 0 4px 12px rgba(0,0,0,.15);
          color: #333;
        }

        .van-image{
          height: 240px;
          width: 100%;
        }

        &__name{
          font-size: 20px;
          margin-top: 12px;
          padding: 0 32px;
          text-align: center;
        }
        &__desc{
          font-size: 16px;
          color: #bbb;
          margin-top: 8px;
          padding: 0 32px;
          text-align: center;
        }
        &__price{
          margin-top: 26px;
          color: $main-color;
          font-size: 20px;
        }
      }

      &__normal{
        margin-bottom: 10px;
      }

    }
  }
}
</style>
