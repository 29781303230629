/**
 * Created by JianJia.Zhou<jianjia.zhou> on 2023/11/22.
 */
export function getQueryParams(name) {
  const params = new URLSearchParams(location.search)
  return params.get(name)
}

export function platform() {
  const IS_WECHAT = (/micromessenger/i).test(navigator.userAgent)
  if (IS_WECHAT) {
    return 'wechat'
  }
  return 'alipay'
}

export function decimals(num, fixed = 1) {
  return parseFloat(num).toFixed(fixed)
}

export default {
  platform,
  getQueryParams,
  decimals
}
