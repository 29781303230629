<template>
  <header>
    <div class="meta">
      <div
        class="container"
        flex="main:justify"
      >
        <div class="title">多多收银系统</div>
        <router-link
          to="/cart"
          class="cart"
        >
          <van-icon
            name="shopping-cart-o"
            size="18px"
          /> 购物车
        </router-link>
      </div>
    </div>
    <layout-nav />
  </header>
</template>

<script>
import LayoutNav from '@/components/Layout/modules/Nav'
export default {
  name: 'LayoutHeader',
  components: { LayoutNav },
  data() {
    return {

    }
  },
  methods: {
    handleClick() {
      this.$router.push({ path: '/cart' })
    }
  }
}
</script>

<style lang="scss" scoped>
header{
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  width: 100%;
  left: 0;
  z-index: 9;
  .meta{
    background: #413E3D;
    color: #DDDDDD;
    padding: 8px 0;
    .cart{
      cursor: pointer;
      color: #fff;
      display: block;
    }
  }
}

</style>
