function set(key, value) {
  return localStorage.setItem(key, JSON.stringify(value))
}

function get(key) {
  try {
    const result = localStorage.getItem(key)
    if (result) {
      return JSON.parse(result)
    }
  } catch (e) {
    return null
  }
}

function remove(key) {
  return localStorage.removeItem(key)
}

export default {
  get,
  set,
  remove
}
