module.exports = {
  title: '网上商城 多多收银官方商城',
  website: 'http://www.duoduosoft.cn/',
  site: {
    cert: '91520198MACMUYR66M',
    beian: '黔ICP备2023012819号-2',
    address: '贵州省贵阳市观山湖区启林创客小镇B栋203'
  },
  contact: {
    tel: '0851-86798933',
    email: 'lenjent@foxmail.com',
    qq: '1184993573'
  }
}
