<template>
  <footer>
    <div
      class="container"
      flex="main:left cross:center"
    >
      <div
        class="company"
      >
        <div class="title">多多收银</div>
        <div class="item">营业执照：{{ site.cert }}</div>
        <div class="item">备案号码：<a
          href="https://beian.miit.gov.cn/"
          target="_blank"
        >{{ site.beian }}</a></div>
        <div class="item">地址：{{ site.address }}</div>
      </div>
      <div
        class="contact"
      >
        <div class="title">联系我们</div>
        <div class="item">电话：<a :href="`tel:${ contact.tel}`">{{ contact.tel }}</a></div>
        <div class="item">邮箱：<a :href="`mailto:${contact.email}`">{{ contact.email }}</a></div>
        <div class="item">Q Q：<a
          :href="`tencent://message/?uin=${contact.qq}`"
        >{{ contact.qq }}</a></div>
      </div>
      <div
        v-if="false"
        class="cert"
      >
        <div class="title">资质证书</div>
        <div class="item">工商营业执照</div>
        <div class="item">增值电信业务经营许可证</div>
        <div class="item">&nbsp;</div>
      </div>
      <div class="wechat-qrcode">
        <div class="wechat-qrcode__txt"><van-icon
          name="wechat"
          color="#25DA6F"
          size="14px"
        /> 微信咨询</div>
        <img
          :src="require('@/assets/wechat-qrcode.jpg')"
          alt=""
        >
      </div>
    </div>
    <div class="copyright">
      贵州多多收银软件技术有限公司 Copyright © 2018-{{ endTime }} Rights Reserved.
    </div>
  </footer>
</template>

<script>
import dayjs from 'dayjs'
import setting from '@/setting'
export default {
  name: 'LayoutFooter',
  data() {
    return {
      site: setting.site,
      contact: setting.contact
    }
  },
  computed: {
    endTime() {
      return dayjs().year()
    }
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
@import "@/style/variable.scss";
footer{
  background: #fafafa;
  padding: 30px 0 12px;
  .container{
    position: relative;
    .wechat-qrcode{
      position: absolute;
      right: 10px;
      top: 10px;
      &__txt{
        text-align: center;
        font-size: 14px;
      }
      img{
        width: 120px;
        height: 120px;
      }
    }
  }

  .contact,
  .cert,
  .company{
    text-align: left;
    width: 50%;

    .title{
      color: #6c757d;
      font-weight: 500;
      font-size: 18px;
      padding: 8px 0;
    }
    .item{
      padding: 4px 0;
      color: #6c757d;
    }
    a{
      color: #6c757d;
      &:hover{
        color: $main-color;
      }
    }
  }
  .copyright{
    border-top: .01rem solid #f1f1f1;
    text-align: center;
    padding: 12px 0 24px;
    margin-top: 30px;
  }
}
</style>
