<template>
  <div
    class="nav-warp"
    flex="cross:center"
  >
    <template v-for="item in nav">
      <div
        :key="item.id"
        :ref="`nav_${item.id}`"
        class="nav__item"
        :class="{'nav__item-active': activeId === item.id}"
        @click="$router.push(item.id ? `/m/cate/${item.id}` : '/m/index').catch(()=>{})"
      >
        {{ item.name }}
      </div>
    </template>
    <div
      class="nav-slider"
      :style="sliderStyle"
    />
  </div>
</template>

<script>
import { website } from '@/setting'
export default {
  name: 'LayoutMNav',
  data() {
    return {
      activeId: 0,
      website: website,
      nav: [],
      sliderStyle: {}
    }
  },
  computed: {
  },
  watch: {
    $route(route) {
      const id = route.params.id
      this.activeId = +id || 0
    },
    activeId() {
      this.sliderStyle = this.getNavStyle()
    }
  },
  mounted() {
    this.getCate()
    const id = this.$route.params.id
    this.activeId = +id || 0

    const index = setInterval(() => {
      const ref = `nav_${this.activeId}`
      if (this.$refs[ref]) {
        clearInterval(index)
        this.sliderStyle = this.getNavStyle()
      }
    }, 10)
  },
  methods: {
    handleClick(item) {
      let url = ''
      if (item.id !== this.activeId) {
        url = item.id ? `/cate/${item.id}` : '/'
      }
      if (url) {
        this.$router.push(url)
      }
    },
    getNavStyle() {
      const ref = `nav_${this.activeId}`
      if (!this.$refs[ref]) return {}
      const rect = this.$refs[ref][0].getBoundingClientRect()
      const left = rect.left
      return {
        transform: `translateX(${left + 14}px)`
      }
    },
    getCate() {
      this.$api.mch.cate().then(res => {
        this.nav = res.data
        this.nav.unshift({ name: '推荐', id: 0 })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/style/variable';
.nav-warp{
  padding: 0 .6rem;
  background: #fff;
  position: relative;
  .nav{
    &__item{
      color: #333;
      font-size: 1rem;
      cursor: pointer;
      flex: 1;
      text-align: center;
      position: relative;
      padding: .8rem 0;
      &:first-child{
        margin-left: 0;
      }
      &-active{
        color: $main-color;
      }
    }
  }

  .nav-slider{
    position: absolute;
    bottom: .5rem;
    height: 1px;
    width: 20px;
    background: $main-color;
    transform: translateX(20px);
    transition: transform .3s;
  }
}
</style>
