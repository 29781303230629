import { render, staticRenderFns } from "./Order.vue?vue&type=template&id=04145e52&scoped=true"
import script from "./Order.vue?vue&type=script&lang=js"
export * from "./Order.vue?vue&type=script&lang=js"
import style0 from "./Order.vue?vue&type=style&index=0&id=04145e52&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/registry.npmmirror.com+vue-loader@15.11.1_css-loader@6.8.1_vue-template-compiler@2.7.15_webpack@5.89.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04145e52",
  null
  
)

export default component.exports