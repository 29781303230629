/**
 * Created by JianJia.Zhou<jianjia.zhou> on 2023/11/22.
 */
import request from '@/utils/request'

export function cartData() {
  return request({
    url: `/mch/order/cart`,
    method: 'get'
  })
}

export function cartStore(data) {
  return request({
    url: `/mch/order/cart`,
    method: 'post',
    data
  })
}

export function create(data) {
  return request({
    url: `/mch/order/create`,
    method: 'post',
    data
  })
}

export function detail(id) {
  return request({
    url: `/mch/order/${id}/detail`,
    method: 'get'
  })
}

export default {
  cartData,
  cartStore,
  create,
  detail
}
