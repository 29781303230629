/**
 * Created by JianJia.Zhou<jianjia.zhou> on 2023/11/22.
 */
import request from '@/utils/request'

export function query(id) {
  return request({
    url: `/mch/payment/${id}/query`,
    method: 'post'
  })
}

export default {
  query
}
