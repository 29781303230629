<template>
  <div class="introduce">
    <video
      ref="video"
      class="video"
      src="https://weicaimaster.oss-cn-shenzhen.aliyuncs.com/video/20240129/9c911cdacec1d04711ff57a3b3dfcf99.mp4"
    />
    <div
      class="video-layer"
      :class="{'video-layer__pause': !isPlay}"
      flex="main:center cross:center"
      @click="handlePlay"
    >
      <div
        v-if="!isPlay"
        class="play"
      />
    </div>
    <footer flex="main:left cross:center">
      <div
        class="goods-info"
        flex="main:left cross:center"
      >
        <img
          src="https://weicaimaster.oss-cn-shenzhen.aliyuncs.com/image/20240129/ea3cf7deca779b015b922aee83a9a790.jpg"
          alt=""
          class="cover"
        >
        <div class="intro">
          <div class="name">{{ goodsInfo.name }}</div>
          <div class="price">&yen;{{ goodsInfo.sale_price }} / {{ goodsInfo.unit?goodsInfo.unit:'份' }}</div>
        </div>
      </div>
      <a
        class="buy-btn"
        role="button"
        flex="main:center cross:center"
        href="https://m.duoduosoft.cn/store/index/78?redirect=/order/goods_detail/8233/v1/0"
      >
        点我购买
      </a>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'Introduce',
  data() {
    return {
      isPlay: false,
      goodsInfo: {}
    }
  },
  computed: {},
  created() {
    this.getDetail()
  },
  methods: {
    handlePlay() {
      if (this.isPlay) {
        this.$refs.video.pause()
      } else {
        this.$refs.video.play()
      }
      this.isPlay = !this.isPlay
    },
    getDetail() {
      this.$api.mch.goodsDetail(8233).then(res => {
        this.goodsInfo = res.data
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.introduce {
  background: #000;
  height: 100vh;
  overflow: hidden;
  video{
    width: 100%;
  }
  .video-layer{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: transparent;
    z-index: 99;
    &.video-layer__pause{
      background: rgba(0,0,0,.6);
    }
    .play{
      background: url("@/assets/play.png") no-repeat center;
      background-size: cover;
      width: 20vw;
      height: 20vw;
      transform: translateY(-5vh)
    }
  }
  footer{
    z-index: 100;
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 8vh;
    background: #fff;
    overflow: hidden;
    .goods-info{
      flex: 1;
      padding: 0 1vw;
      .cover{
        width: 7.2vh;
        height: 7.2vh;
        border-radius: 2px;
      }
      .intro{
        padding: 0 3vw;
        .name{
          font-size: 2.1vh;
        }
        .price{
          color: #FF5556;
          font-size: 1.6vh;
        }
      }
    }
    .buy-btn{
      background: #FF5556;
      height: 100%;
      color: #fff;
      width: 30vw;
      font-size: 2.1vh;
      animation: ani-buy 1s infinite linear;
    }
  }
}
@keyframes ani-buy {
  0%{transform: scale(1)}
  50%{transform: scale(1.1)}
  100%{transform: scale(1)}
}
</style>
