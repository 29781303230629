<template>
  <div class="wrapper">
    <!--  banner  -->
    <van-swipe
      class="dd-swipe"
      :autoplay="5000"
      indicator-color="white"
    >
      <van-swipe-item
        v-for="(item, key) in banner"
        :key="key"
      >
        <van-image
          width="100%"
          :src="item.img"
          height="16rem"
          fit="cover"
        />
      </van-swipe-item>
    </van-swipe>
    <div
      v-if="hots.length"
      class="cate-container"
    >
      <div class="title">热销商品</div>
      <div
        class="goods-container"
        flex="dir:left wrap:wrap"
      >
        <template v-for="arr in hots">
          <div
            v-for="item in arr"
            :key="`goods`+item.id"
            class="goods-item"
            @click="$router.push(`/m/detail/${item.id}`)"
          >
            <van-image
              fit="cover"
              height="15.6rem"
              :src="item.cover_img+'?x-oss-process=image/resize,w_500,limit_0'"
            />
            <span
              class="goods-item__name line-1"
              flex="main:center cross:center"
            >{{ item.name }}</span>
            <span
              class="goods-item__desc line-1"
              flex="main:center cross:center"
            >&nbsp;{{ item.desc }}</span>
            <span
              class="goods-item__price"
              flex="main:center cross:center"
            > &yen;{{ item.sale_price }}</span>
          </div>
        </template>
      </div>
    </div>
    <div class="cate-container">
      <div class="title">{{ category.name }}</div>
      <div
        class="goods-container"
        flex="dir:left wrap:wrap"
      >
        <template v-for="arr in goodsList">
          <div
            v-for="item in arr"
            :key="`goods`+item.id"
            class="goods-item"
            @click="$router.push(`/m/detail/${item.id}`)"
          >
            <van-image
              fit="cover"
              height="15.6rem"
              :src="item.cover_img+'?x-oss-process=image/resize,w_500,limit_0'"
            />
            <span
              class="goods-item__name line-1"
              flex="main:center cross:center"
            >{{ item.name }}</span>
            <span
              class="goods-item__desc line-1"
              flex="main:center cross:center"
            >&nbsp;{{ item.desc }}</span>
            <span
              class="goods-item__price"
              flex="main:center cross:center"
            > &yen;{{ item.sale_price }}</span>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'IndexView',
  data() {
    return {
      banner: [],
      hots: [],
      goodsList: [],
      category: {}
    }
  },
  computed: {
  },
  async mounted() {
    await this.getBanner()
    await this.getGoods()
  },
  methods: {
    async getBanner() {
      await this.$api.market.setting().then(res => {
        this.banner = res.data
      })
    },
    async getGoods() {
      await this.$api.mch.goods().then(res => {
        this.hots = res.data.hots
        this.goodsList = res.data.goods_list
        this.category = res.data.category
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/style/variable.scss";
.wrapper{
  background: #f5f5f5;
  .dd-swipe .van-swipe-item {
    color: #fff;
    font-size: 20px;
    height: 16rem;
    text-align: center;
    img{
      height: inherit;
      width: 100%;
    }
  }
  .cate-container{
    margin-top: 1.8rem;
    background: #f5f5f5;
    .title{
      text-align: center;
      position: relative;
      font-size: 1.6rem;
      font-weight: 700;
      padding: 1.4rem 0;
      &:after{
        content: "";
        position: absolute;
        bottom: .8rem;
        height: 3px;
        width: 2.2rem;
        background: $main-color;
        border-radius: .8rem;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    .goods-container{
      margin-top: 1.4rem;
      .goods-item{
        width: calc((100% - .4rem) /2) ;
        margin-right: .4rem;
        margin-bottom: .4rem;
        height: 22.6rem;
        background: #fff;
        border-radius: 0 .15rem .15rem 0;
        overflow: hidden;
        &:nth-child(2n){
          margin-right: 0;
          border-radius: .15rem 0 0 .15rem;
        }

        &__name{
          font-weight: 700;
          font-size: 1.34rem;
          padding: .2rem .5rem;
        }

        &__desc{
          font-size: 1.2rem;
          color: #999;
          padding: 0 .5rem;
        }

        &__price{
          color: $main-color;
          padding: 0 .5rem;
          font-size: 1.2rem;
        }
      }
    }
  }

}
</style>
