<template>
  <div
    class="nav-warp"
    flex="cross:center"
  >
    <div
      class="container"
      flex="main:left cross:center"
    >
      <a
        :href="website"
        class="logo-group"
        flex="main:left cross:center"
      ><div class="logo" /> <span>多多收银官方商城</span></a>
      <div
        class="nav"
        flex="main:left cross:center"
      >
        <template v-for="item in nav">
          <router-link
            :key="item.id"
            class="nav__item"
            :class="{'nav__active': activeId === item.id}"
            :to="item.id ? `/cate/${item.id}` : '/'"
          >
            {{ item.name }}
          </router-link>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { website } from '@/setting'
export default {
  name: 'LayoutNav',
  data() {
    return {
      activeId: 0,
      website: website,
      nav: []
    }
  },
  computed: {},
  watch: {
    $route(route) {
      const id = route.params.id
      this.activeId = +id || 0
    }
  },
  mounted() {
    this.getCate()
    const id = this.$route.params.id
    this.activeId = +id || 0
  },
  methods: {
    handleClick(item) {
      let url = ''
      if (item.id !== this.activeId) {
        url = item.id ? `/cate/${item.id}` : '/'
      }
      if (url) {
        this.$router.push(url)
      }
    },
    getCate() {
      this.$api.mch.cate().then(res => {
        this.nav = res.data
        this.nav.unshift({ name: '首页', id: 0 })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/style/variable';
.nav-warp{
  padding: 12px 0;
  background: #fff;
  height: 110px;
  .logo-group{
    .logo{
      background: url("@/assets/logo-big.png") no-repeat center;
      background-size: contain  ;
      height: 40px;
      width: 110px;
      margin-right: 16px;
    }
    span{
      font-size: 22px;
      font-weight: bold;
      color: #555;
    }
  }
  .nav{
    margin-left: 108px;
    &__item{
      color: #333;
      font-size: 18px;
      cursor: pointer;
      margin-right: 48px;
      &:hover{
        color: $main-color;
      }
      &:last-child{
        margin-right: 0;
      }
    }
    &__active{
      color: $main-color;
    }
  }
}
</style>
