/**
 * Created by JianJia.Zhou<jianjia.zhou> on 2023/11/22.
 */
import request from '@/utils/request'

export function cate() {
  return request({
    url: `/mch/cate/lists`,
    method: 'get'
  })
}

export function goods(params) {
  return request({
    url: `/mch/goods/lists`,
    method: 'get',
    params
  })
}

export function goodsDetail(id) {
  return request({
    url: `/mch/goods/${id}/detail`,
    method: 'get'
  })
}

export default {
  cate,
  goods,
  goodsDetail
}
