<template>
  <div
    v-if="Object.keys(detail).length > 0"
    class="container"
    flex="main:left"
  >
    <div class="cover-box">
      <van-image
        class="cover-img"
        :src="detail.cover_img+'?x-oss-process=image/resize,w_500,limit_0'"
        flex="main:center cross:center"
      />
      <div
        class="cover-desc"
        flex="main:center cross:center"
      >
        <div class="cover-desc__item"><van-icon name="passed" />官方自营</div>
        <div class="cover-desc__item"><van-icon name="passed" />7x12小时客服服务</div>
      </div>
      <div class="cover-contact">
        对于购买 零售行业软件 有任何疑问？ <a :href="`tencent://message/?uin=${contact.qq}`">咨询人工客服 &gt;</a>
      </div>
    </div>
    <div class="goods-info">
      <div class="goods-info__name">{{ detail.name }}</div>
      <div class="goods-info__desc">{{ detail.desc }}</div>
      <div
        class="goods-info__price"
        flex="main:left cross:bottom"
      >
        <span class="text">价格</span>
        <span class="symbol">&yen;</span>
        <span class="number">{{ detail.sale_price }}</span>
      </div>
      <div
        class="goods-info__number"
        flex="main:left cross:center"
      >
        <span>数量</span>
        <van-stepper
          v-model="number"
          :min="1"
          :max="detail.stock_num"
          input-width="72px"
          button-size="42px"
        />
      </div>
      <div
        class="goods-info__sale-price"
        flex="main:justify cross:center"
      >
        <span>{{ detail.name }}</span>
        <span>&yen;{{ (detail.sale_price * number).toFixed(2) }}</span>
      </div>
      <div
        class="goods-info__btns"
        flex="main:left cross:center"
      >
        <van-button
          type="primary"
          class="cart-btn"
          icon="shopping-cart-o"
          @click="handleAddCart('cart')"
        >加入购物车</van-button>
        <van-button
          type="primary"
          class="buy-btn"
          @click="handleAddCart('buy')"
        >立即购买</van-button>
      </div>
    </div>
  </div>
</template>

<script>
import setting from '@/setting'
import { save } from '@/utils/cart'
export default {
  name: 'DetailView',
  data() {
    return {
      id: 0,
      detail: {},
      contact: setting.contact,
      number: 1
    }
  },
  computed: {},
  mounted() {
    this.id = this.$route.params.id
    this.getDetail()
  },
  methods: {
    handleAddCart(type) {
      const product = JSON.parse(JSON.stringify(this.detail))
      product.count = this.number

      this.$store.dispatch('cart/store', save(product, 'add')).then(res => {
        if (type === 'cart') {
          this.$router.push('/cart')
        } else {
          this.$router.push('/order')
        }
      })
    },
    getDetail() {
      this.$api.mch.goodsDetail(this.id).then(res => {
        this.detail = res.data
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/style/variable.scss";

.container{
  padding-top: 36px;
  padding-bottom: 72px;
}
.cover-box{
  width: 420px;
  .cover-img::v-deep{
    box-shadow: 0 0 12px rgba(0,0,0,.05);
    transition: .5s;
    background: #fff;
    text-align: center;
    width: 100%;
    min-height: 400px;
    padding: 10px 0;
    &:hover{
      box-shadow: unset;
    }
    img{
      height: 420px;
      width: 400px;
    }
  }
  .cover-desc{
    font-size: 16px;
    margin-top: 24px;
    &__item{
      margin-left: 30px;
      color: #A3A3A3;
      &:first-child{
        margin-left: 0;
      }
    }
  }
  .cover-contact{
    border-top: .001rem solid #eee;
    font-size: 16px;
    color: #A3A3A3;
    text-align: center;
    padding: 30px 0;
    margin-top: 20px;
    a{
      color: $main-color;
    }
  }
}

.goods-info{
  padding-left: 32px;
  flex: 1;

  &__name{
    font-size: 36px;
  }
  &__desc{
    color: #A3A3A3;
    margin-top: 12px;
  }
  &__price{
    color: $main-color;
    font-weight: 500;
    margin: 24px 0 0;
    .text{
      border: .01rem solid $main-color;
      color: $main-color;
      font-size: 20px;
      font-weight: normal;
      padding: 4px 12px;
      border-radius: 4px;
    }
    .symbol{
      font-size: 18px;
      margin-left: 18px;
    }
    .number{
      margin-left: 8px;
      font-size: 72px;
      line-height: 56px;
    }
  }
  &__number{
    margin-top: 42px;
    font-size: 16px;
    span{
      margin-right: 16px;
    }
  }
  &__sale-price{
    background: #fff;
    height: 72px;
    text-align: center;
    font-size: 16px;
    margin-top: 124px;
    padding: 0 24px;

    span:last-child{
      color: $main-color;
      margin-left: 32px;
    }
  }
  &__btns{
    margin-top: 40px;
    .cart-btn,.buy-btn{
      border: none;
      border-radius: 0;
      height: 84px;
      font-size: 22px;
    }
    .cart-btn{
      width: 220px;
      margin-right: 32px;
      background: #fff;
      color: #333;
    }
    .buy-btn{
      flex: 1;
      background: linear-gradient(to right, #F18C0F ,#E8420B);
    }
  }
}
</style>
