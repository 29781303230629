<template>
  <div>
    <div class="container order-view">
      <template v-if="Object.keys(cartData).length">
        <!--  收货人信息    -->
        <div class="card card__address">
          <div
            class="title"
            flex="main:left cross:center"
          >收货人信息</div>
          <div class="card-box">
            <div
              class="input-group"
              flex="main:justify cross:center"
            >
              <div
                class="input-item"
                flex="main:left cross:center"
              >
                <label>姓名</label>
                <input
                  v-model="address.name"
                  type="text"
                  class="input"
                  placeholder="输入姓名"
                >
              </div>
              <div
                class="input-item"
                flex="main:left cross:center"
              >
                <label>电话</label>
                <input
                  v-model="address.phone"
                  type="text"
                  class="input"
                  placeholder="输入电话"
                >
              </div>
            </div>
            <div
              class="input-group"
              flex="main:justify cross:center"
            >
              <div
                class="input-item"
                flex="main:left cross:center"
              >
                <label>地址</label>
                <input
                  v-model="address.address"
                  type="text"
                  class="input"
                  placeholder="输入地址"
                >
              </div>
            </div>
            <div
              class="input-group"
              flex="main:justify cross:center"
            >
              <div
                class="input-item"
                flex="main:left cross:center"
              >
                <label>备注</label>
                <input
                  v-model="remark"
                  type="text"
                  class="input"
                  placeholder="输入备注"
                >
              </div>
            </div>
          </div>
        </div>
        <!--   商品   -->
        <cart-goods />
        <div
          class="btns"
          flex="main:justify cross:center"
        >
          <span />
          <van-button
            type="primary"
            class="buy-btn"
            @click="handleOrder"
          >确认购买</van-button>
        </div>
      </template>
      <!--  购物车为空    -->
      <template v-else>
        <van-empty
          description="购物车暂无数据"
          flex="main:center cross:center"
        >
          <router-link to="/">
            <van-button
              round
              type="primary"
              class="empty-btn"
            >继续购物</van-button>
          </router-link>
        </van-empty>
      </template>
    </div>
    <!--  支付    -->
    <van-overlay
      :show="payShow"
      :custom-style="{zIndex: 999, background: 'rgba(0,0,0,1)'}"
    >
      <div
        class="pay-info"
        flex="dir:top main:center cross:center"
      >
        <img
          :src="pay.qrcode"
          alt=""
        >
        <p>请使用支付宝或微信扫码进行支付</p>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import CartGoods from '@/components/CartGoods'
export default {
  name: 'OrderView',
  components: { CartGoods },
  data() {
    return {
      payShow: false,
      address: {
        name: '',
        phone: '',
        address: ''
      },
      remark: '',
      pay_type: 1,
      pay: { id: '', qrcode: '', orderId: '' }
    }
  },
  computed: {
    cartData() {
      return this.$store.getters.cartData
    }
  },
  watch: {
    cartData: {
      deep: true,
      immediate: true,
      handler() {

      }
    }
  },

  methods: {
    handleOrder() {
      if (!this.address.name || !this.address.phone || !this.address.address) {
        this.$dialog.alert({ message: '请完整填写联系方式：姓名，电话，地址' })
        return true
      }

      const loading = this.$ddLoading()
      this.$api.order.create({ address: this.address, remark: this.remark, source: 13 }).then(res => {
        loading.close()
        this.pay.qrcode = res.data.qrcode
        this.pay.id = res.data.pay_id
        this.pay.orderId = res.data.order_id
        this.payShow = true
        this.$store.dispatch('cart/clear')
        this.queryPay()
      }).catch(() => {
        loading.close()
      })
    },
    queryPay() {
      const index = setInterval(() => {
        this.$api.pay.query(this.pay.id).then(res => {
          if (res.data.status) {
            this.$router.push(`/order/${this.pay.orderId}/success`)
            clearInterval(index)
          }
        })
      }, 1000)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/style/variable.scss";
.order-view {
  padding: 36px 0 72px;

  .card{
    margin-bottom: 36px;
    &__address{
      border: 1px solid #eee;
    }
    .title{
      background: #eee;
      height: 48px;
      padding: 0 32px;
      font-size: 16px;
    }

    .card-box{
      background: #fff;
      padding: 32px;

      .input-group{
        margin-bottom: 20px;
        .input-item{
          flex: 1;
          label{
            width: 80px;
            font-size: 18px;
            font-weight: 500;
          }
          .input{
            flex: 1;
            border: 1px solid #fff;
            background: #eee;
            padding: 6px 12px;
            height: 48px;
            line-height: 48px;
            font-size: 18px;
            transition: .5s;
            &:focus{
              border: 1px solid $main-color;
              background: #fefefe;
            }
          }
          &:first-child{
            margin-right: 72px;
          }
          &:last-child{
            margin-right: 0;
          }
        }
      }
    }
  }

  .empty-btn,
  .buy-btn{
    width: 160px;
    height: 56px;
    border-radius: 0;
    background: linear-gradient(to right, #F18C0F ,#E8420B);
  }

  .empty-btn{
    width: 180px;
    height: 42px;
  }
}
.pay-info{
  height: 80vh;
  img {
    width: 280px;
  }
  p{
    color: #fff;
    padding: 12px 0;
    font-size: 16px;
  }
}
</style>
