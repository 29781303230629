<template>
  <van-overlay
    :show="show"
    class="dd-overlay"
    lock-scroll
  >
    <van-loading
      :color="color"
      :type="type"
      :size="size"
    >{{ text }}</van-loading>
  </van-overlay>
</template>

<script>
export default {
  name: 'DDLoading',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    text: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: '#fff'
    },
    type: {
      type: String,
      default: 'spinner'
    },
    size: {
      type: [String, Number],
      default: '24px'
    }
  },
  data() {
    return {}
  },
  computed: {},
  methods: {
  }
}
</script>

<style lang="scss" scoped>
.dd-overlay {
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
