<template>
  <div class="cart-goods">
    <table class="table">
      <thead>
        <tr>
          <th>商品</th>
          <th width="160px">单价（元）</th>
          <th width="160px">数量</th>
          <th width="160px">小计（元）</th>
          <th width="160px">操作</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(item, key) in cartData"
          :key="key"
        >
          <td>
            <div
              class="goods-info"
              flex="main:left cross:center"
            >
              <van-image
                height="80px"
                width="80px"
                :src="item.cover_img[0]+'?x-oss-process=image/resize,w_500,limit_0'"
              />
              <span> {{ item.name }}</span>
            </div>
          </td>
          <td>{{ item.sale_price }}</td>
          <td>
            <van-stepper
              v-model="item.count"
              :max="item.stock_num"
              :min="0"
              input-width="32px"
              button-size="26px"
              @minus="handleChange(item, 'min')"
              @plus="handleChange(item, 'add')"
            />
          </td>
          <td>{{ (item.count * item.sale_price).toFixed(2) }}</td>
          <td>
            <span
              class="delete"
              @click="handleDelete(item)"
            >删除</span>
          </td>
        </tr>
      </tbody>
    </table>
    <div
      class="total"
      flex="main:justify cross:center"
    >
      <span />
      <span>总计：&yen;{{ totalPrice }}</span>
    </div>
  </div>
</template>

<script>
import { calcPrice, save } from '@/utils/cart'
export default {
  name: 'CartGoods',
  data() {
    return {}
  },
  computed: {
    cartData() {
      return this.$store.getters.cartData
    },
    totalPrice() {
      return calcPrice()
    }
  },
  watch: {
    cartData: {
      deep: true,
      immediate: true,
      handler(val) {

      }
    }
  },
  methods: {
    handleChange(product, type) {
      if (type === 'add') {
        product.count += 1
      } else if (type === 'min') {
        product.count -= 1
      }
      const loading = this.$ddLoading()
      this.$store.dispatch('cart/store', save(product)).then(res => {
        loading.close()
      })
    },
    handleDelete(product) {
      product.count = 0
      const loading = this.$ddLoading()
      this.$store.dispatch('cart/store', save(product)).then(res => {
        loading.close()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/style/variable.scss";
.cart-goods {
  .input{
    border: 1px solid #fff;
    background: #eee;
    padding: 6px 12px;
    height: 42px;
    line-height: 42px;
    font-size: 16px;
    transition: .5s;
    width: 100%;
    &:focus{
      border: 1px solid $main-color;
      background: #fefefe;
    }
  }

  .delete{
    color: $main-color;
    cursor: pointer;
  }

  .table{
    width: 100%;
    border: 1px solid #ebebeb;
    border-spacing: 0;
    margin-bottom: 42px;
    thead{
      background: #EBEBEB;
      color: #666;
      height: 42px;
    }

    tbody{
      background: #fff;
    }

    tr>th,
    tr>td{
      padding: 12px 0;
      font-size: 16px;
      text-align: center;
      border-top: 1px solid #eee;
    }

    .goods-info{
      width: 310px;
      padding: 0 16px;

      span{
        text-align: left;
        padding-left: 12px;
      }
    }
  }

  .total{
    background: #EBEBEB;
    height: 56px;
    font-size: 16px;
    padding: 0 32px;
    margin-bottom: 42px;
    span{
      color: $main-color;
      font-weight: 500;
    }
  }
}
</style>
