/**
 * Created by JianJia.Zhou<jianjia.zhou> on 2023/12/27.
 */
import Vue from 'vue'
import Loading from './index.vue'

const DDLoadingConstructor = Vue.extend(Loading) // 注册实例

export const DDLoading = {
  install(Vue) {
    Vue.prototype.$ddLoading = (opts) => {
      const defaultOpts = {
        duration: 250000,
        show: true,
        text: '加载中...',
        color: '#fff',
        type: 'spinner',
        size: '32px'
      }
      opts = Object.assign(defaultOpts, opts)

      const cartNotice = new DDLoadingConstructor()
      const instance = cartNotice.$mount(document.createElement('div'))
      document.body.appendChild(cartNotice.$el)
      instance.show = opts.show
      instance.text = opts.text
      instance.color = opts.color
      instance.type = opts.type
      instance.size = opts.size

      return {
        close() {
          document.body.removeChild(instance.$el)
        }
      }
    }
  }
}
export default {
  DDLoading
}
