// import { md5 } from 'md5js'
import store from '@/store'
import { decimals } from './index'

export function save(product, type) {
  let cartData = store.getters.cartData
  cartData = cartData || {}
  // num 不参与md5
  const num = product.count
  delete product.count
  const productKey = product.id// md5(JSON.stringify(product), 32)
  if (typeof cartData[productKey] !== 'undefined') {
    if (type === 'add') {
      cartData[productKey].count += num
    } else if (type === 'min') {
      cartData[productKey].count -= num
    } else {
      cartData[productKey].count = num
    }
  } else {
    product.count = num
    cartData[productKey] = product
  }
  if (cartData[productKey].count === 0) {
    delete cartData[productKey]
  }

  return cartData
}

export function clear() {
  return store.dispatch('cart/data', {})
}

export function calcPrice() {
  const cartData = store.getters.cartData
  let sale_price = 0
  for (const key in cartData) {
    const obj = cartData[key]
    let singleSalePrice = obj.sale_price
    if (typeof obj.selectedSpec !== 'undefined' && obj.selectedSpec.length) {
      singleSalePrice = 0
      obj.selectedSpec.forEach(spec => {
        singleSalePrice += spec.sale_price * 1
      })
    }
    sale_price += parseFloat(singleSalePrice) * obj.count
  }

  return decimals(sale_price, 2)
}

export default {
  save,
  clear,
  calcPrice
}
