<template>
  <div class="float-menu">
    <div
      v-for="(item, key) in menus"
      v-show="item.show"
      :key="key"
      class="float-menu__item"
      flex="dir:top main:center cross:center"
      @click="handleClickItem(item)"
    >
      <van-icon
        :name="item.icon"
        size="32px"
      />
      <span>{{ item.name }}</span>
      <span v-if="item.number">{{ item.number }}</span>
    </div>
  </div>
</template>

<script>
import setting from '@/setting'
export default {
  name: 'FloatMenu',
  props: {
    scrollTop: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      setting: setting
    }
  },
  computed: {
    menus() {
      return [
        { name: '服务热线', icon: 'phone', number: setting.contact.tel, show: true },
        { name: '客服QQ', icon: 'qq', number: '', event: this.redirectQQ, show: true },
        { name: '返回顶部', icon: 'back-top', number: '', event: this.backTop, show: this.showBackTop }
      ]
    },
    showBackTop(scrollTop) {
      return scrollTop >= 300
    }
  },
  mounted() {
  },
  methods: {
    handleClickItem(item) {
      if (typeof item.event === 'function') {
        item.event()
      }
    },
    redirectQQ() {
      window.location.href = `tencent://message/?uin=${this.setting.contact.qq}`
    },
    backTop() {
      document.documentElement.scrollTop = 0
      document.body.scrollTop = 0
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/style/variable';
.float-menu{
  position: fixed;
  right: 0;
  width: 110px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  bottom: 10vh;
  background: #fff;
  box-shadow: 0 0 10px rgba(0,0,0,.1);
  overflow-x: hidden;

  &__item{
    height: 110px;
    padding: 0 6px;
    cursor: pointer;
    transition: .3s;

    &:first-child{
      background: linear-gradient(225deg, #F18C0F ,#E8420B);
      color: #fff;
    }
    &:nth-child(n+2):hover{
      color: $main-color;
    }

    span{
      text-align: center;
      font-size: 14px;
    }
  }

}
</style>
