/**
 * Created by JianJia.Zhou<jianjia.zhou> on 2023/11/22.
 */
import pay from './pay'
import user from './user'
import store from './store'
import order from './order'
import mch from './mch'
import market from './market'

const api = {
  pay,
  user,
  store,
  order,
  mch,
  market
}
export default api
